<template>
  <div class="billList">
    <div>
      <span v-if="myBrand" class="mr_20">{{ myBrand.name }}</span>
      <span v-else-if="myComp" class="mr_20">{{ myComp.name }}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{
        myShop.name
      }}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div class="search-group-wrap">
          <!-- <div class="search-group-dec">个人查找：</div> -->
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="个人信息">
                  <el-input
                    v-model="searchWd"
                    placeholder="姓名/手机号/身份证号"
                    clearable
                    @keyup.enter.native="serchData"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账单编号">
                  <el-input
                    v-model="searchForm.billNo"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="serchData"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="分期类型">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.stageType"
                    clearable
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in stageTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账单状态">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.billStatus"
                    clearable
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in billStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="search-group-wrap">
          <!-- <div class="search-group-dec">订单筛选：</div> -->
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="申请日期">
                  <el-date-picker
                    style="width: 100%"
                    v-model="apply_date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="setDateRange"
                    @change="serchData"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="放款日期">
                  <el-date-picker
                    style="width: 100%"
                    v-model="loan_date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="setDateRange"
                    @change="serchData"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身份证照片">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.isIdCardImg"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in isIdCardImgList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="绑定银行卡">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.isBindCard"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in isBindCardList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="商家" v-if="myBrand || myComp">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.businessId"
                    :remote-method="businessRemoteMethod"
                    :loading="businessLoading"
                    placeholder="选择商家"
                    clearable
                    filterable
                    remote
                    @focus="businessRemoteMethod()"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in businessList"
                      :key="item.id"
                      :label="item.full_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="商品">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.productId"
                    :remote-method="productRemoteMethod"
                    :loading="productLoading"
                    placeholder="请输入关键词"
                    clearable
                    filterable
                    remote
                    @keyup.enter.native="serchData"
                  >
                    <el-option
                      v-for="item in productList"
                      :key="item.product_id"
                      :label="item.product_name"
                      :value="item.product_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否还款">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.isRepayment"
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in isRepaymentList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="备注：">
                  <el-input
                    v-model="searchForm.remark"
                    placeholder="请输入备注"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="退费时间：">
                  <el-date-picker
                    style="width: 100%"
                    v-model="refund_date"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="setDateRange"
                    @change="onRefundDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <div style="text-align: right">
                <el-button size="small" @click="onExport">导出</el-button>
                <el-button type="primary" size="small" @click="serchData"
                  >搜索</el-button
                >
              </div>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <div class="bill-table">
      <base-table
        :columns="allBillList"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="getBillList"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="business_name"
          label="商家"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.senior_name }} - {{ scope.row.business_full_name }}
          </template>
        </el-table-column>
        <el-table-column
          slot="stage_type"
          label="分期类型"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ stageTypeFormatter(scope.row) }}
            <template v-if="scope.row.stage_type == 1">
              {{ scope.row.stage_count }}期
            </template>
            <template v-if="scope.row.stage_type == 2">
              {{ scope.row.x_stage_count }}+{{ scope.row.y_stage_count }}期
            </template>
            <!-- {{stageTypeFormatter(scope.row)}} {{scope.row.business_full_name}} -->
          </template>
        </el-table-column>
        <!-- <el-table-column
          slot="stage_type"
          label="分期类型"
          align="center"
          :formatter="stageTypeFormatter"
        ></el-table-column> -->
        <el-table-column
          slot="bill_status"
          label="账单状态"
          align="center"
          width="90"
          :formatter="billStatusFormatter"
        ></el-table-column>
        <el-table-column
          slot="is_id_card_img"
          label="是否上传身份证照片"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_id_card_img == 0">否</span>
            <span v-if="scope.row.is_id_card_img == 1">是</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="is_bind_card"
          label="是否绑定银行卡"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_bind_card == 0">否</span>
            <span v-if="scope.row.is_bind_card == 1">是</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="isRepayment"
          label="是否还款"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_repayment == 0">否</span>
            <span v-if="scope.row.is_repayment == 1">是</span>
          </template>
        </el-table-column>
        <el-table-column slot="remark" label="备注" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>

        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          width="80"
          fixed="right"
          v-if="managerType != 0 || getAuth('billInfo')"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="checkDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </base-table>
    </div>
  </div>
</template>

<script>
import { billList } from "@/api/bill.js";
import { suggestBusinessList } from "@/api/business.js";
import { selectProduct } from "@/api/product.js";
import { getIdCard } from "@/utils/lib.js";
import { getMyBrand, getMyComp, getMyShop, getAuth } from "@/utils/index.js";
import columns from "./columns";
import config from "@/utils/config.js";


export default {
  name: "billList",

  data() {
    return {
      managerType: localStorage.getItem("__managerType__"),
      getAuth: getAuth,
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),

      searchWd: "",
      searchForm: {
        page: 1,
        per_page: 10,
        stageType: 0,
        billStatus: 0,
        seniorId: "",
        entityId: "",
        businessId: "",
        isIdCardImg: -1,
        isBindCard: -1,
        isRepayment:-1,
        refundStartTime: '',
        refundEndTime: ''
      },
      isRepaymentList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "未开始还款",
        },
        {
          value: 1,
          label: "已开始还款",
        },
      ],
      isBindCardList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "未绑定银行卡",
        },
        {
          value: 1,
          label: "已绑定银行卡",
        },
      ],
      isIdCardImgList: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "未上传身份证",
        },
        {
          value: 1,
          label: "已上传身份证",
        },
      ],
      ...columns,
      loading: false,
      total: 0,
      tableData: [],
      refund_date: '',
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],
      productLoading: false,
      productList: [],
      apply_date: [],
      loan_date: [],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      stageTypeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "等额还款",
        },
        {
          value: 2,
          label: "组合还款",
        },
      ],
      billStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待资料审核",
        },
        {
          value: 2,
          label: "待分期确认",
        },
        {
          value: 3,
          label: "审核拒绝",
        },
        {
          value: 4,
          label: "生效中",
        },
        {
          value: 5,
          label: "已逾期",
        },
        {
          value: 6,
          label: "已完结",
        },
        {
          value: 7,
          label: "已退款",
        },
        {
          value: 8,
          label: "已取消",
        },
      ],
    };
  },

  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness();
  },
  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      this.getBillList();
    },
    async getBillList() {
      this.searchForm.seniorId = "";
      this.searchForm.entityId = "";
      if (!this.myBrand && !this.myComp && this.myShop) {
        this.searchForm.businessId = this.myShop.id;
      }
      if (!this.searchForm.businessId) {
        if (this.myBrand) {
          this.searchForm.seniorId = this.myBrand.id;
        } else if (this.myComp) {
          this.searchForm.entityId = this.myComp.id;
        }
      }

      if (this.apply_date && this.apply_date.length == 2) {
        this.searchForm.applyStartTime = this.apply_date[0] + " " + "00:00:00";
        this.searchForm.applyEndTime = this.apply_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.applyStartTime = "";
        this.searchForm.applyEndTime = "";
      }
      if (this.loan_date && this.loan_date.length == 2) {
        this.searchForm.ApproveStartTime = this.loan_date[0] + " " + "00:00:00";
        this.searchForm.ApproveEndTime = this.loan_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.ApproveStartTime = "";
        this.searchForm.ApproveEndTime = "";
      }

      this.searchForm.username = "";
      this.searchForm.mobile = "";
      this.searchForm.idCardNo = "";
      this.searchWd = this.searchWd.replace(/\s/g, "");
      const idcard = getIdCard(this.searchWd);
      if (idcard) {
        this.searchForm.idCardNo = idcard;
      } else if (/^1\d{10}$/.test(this.searchWd)) {
        this.searchForm.mobile = this.searchWd;
      } else {
        this.searchForm.username = this.searchWd;
      }

      const { code, data } = await billList(this.searchForm);
      this.loading = true;
      if (code == 200) {
        this.loading = false;
        this.tableData = data.list.map((item) => {
          if ((item.remark || "").length > 20) {
            item.remark = item.remark.substring(0, 18) + "...";
          }
          return item;
        });
        this.total = data.totalCount;
      } else {
        this.loading = false;
      }
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/bill/billInfo",
        query: {
          billNo: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onExport() {
      const url = `${config.BASE_URL}/export/billList?token=${localStorage.getItem("__token__")}&seniorId=${this.searchForm.seniorId || ""}&entityId=${this.searchForm.entityId || ""}&businessId=${this.searchForm.businessId || ""}&productId=${this.searchForm.productId || ""}&billNo=${this.searchForm.billNo || ""}&username=${this.searchForm.username || ""}&mobile=${this.searchForm.mobile || ""}&idCardNo=${this.searchForm.idCardNo || ""}&stageType=${this.searchForm.stageType || ""}&billStatus=${this.searchForm.billStatus || ""}&applyStartTime=${this.searchForm.applyStartTime || ""}&applyEndTime=${this.searchForm.applyEndTime || ""}&ApproveStartTime=${this.searchForm.ApproveStartTime || ""}&ApproveEndTime=${this.searchForm.ApproveEndTime || ""}&isIdCardImg=${this.searchForm.isIdCardImg || ""}&isBindCard=${this.searchForm.isBindCard || ""}&isRepayment=${this.searchForm.isRepayment || ""}`;
      window.open(url, "_blank");
    },
    billStatusFormatter(row) {
      switch (row.bill_status) {
        case 1:
          return "待资料审核";
        case 2:
          return "待分期确认";
        case 3:
          return "审核拒绝";
        case 4:
          return "已生效";
        case 5:
          return "已逾期";
        case 6:
          return "已完结";
        case 7:
          return "已退款";
        case 8:
          return "已取消";
      }
    },
    stageTypeFormatter(row) {
      switch (row.stage_type) {
        case 1:
          return "等额本金";
        case 2:
          return "组合还款";
      }
    },
    onRefundDateChange(val) {
      if (val) {
        this.searchForm.refundStartTime = val[0] + " " + "00:00:00";
        this.searchForm.refundEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.refundStartTime = "";
        this.searchForm.refundEndTime = "";
      }
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
    productRemoteMethod(query) {
      if (this.searchForm.businessId) {
        if (query != "") {
          this.productLoading = true;
          setTimeout(() => {
            this.productLoading = false;
            this.getSelectProduct(query);
          }, 200);
        } else {
          this.productList = [];
        }
      } else {
        this.$message.warning("请先选择商家");
      }
    },
    async getSelectProduct(query) {
      const param = {
        business_id: this.searchForm.businessId,
        product_name: query,
      };
      const { code, data } = await selectProduct(param);
      if (code == 200) {
        this.productList = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>